<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4
            class="page-title"
            v-if="currentRouteName == 'orientation-award-update'"
          >
            Edit Orientation Award
          </h4>
          <h4 class="page-title" v-else>Add Orientation Award</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Orientation Batch
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6" v-if="
                        hasPermissions([
                          'trainer-orientation-award-create',
                          'trainer-orientation-award-edit',
                        ])
                      ">
                  <v-select
                    class="custom-selectCSS"
                    :clearable="false"
                    @option:selected="getSelectedBatch($event)"
                    v-model="selectedBatch"
                    :selectable="(options) => isSelectableAward(options)"
                    :options="orientationBatch"
                    label="batch_name"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('Orientation Batch')"
                    :vuelidateErrors="{
                      errors: v$.selectedBatch.$errors,
                      value: 'Orientation Batch',
                    }"
                  ></v-errors>
                </div>

                <div class="col-md-6" v-else-if="
                        hasPermissions([
                          'trainer-orientation-award-list'
                        ])
                      ">
                  <v-select
                    class="custom-selectCSS"
                    disabled
                    :clearable="false"
                    v-model="selectedBatch"
                    label="batch_name"
                  >
                  </v-select>
                </div>

              </div>

              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Talent Award
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6" v-if="
                        hasPermissions([
                          'trainer-orientation-award-create',
                          'trainer-orientation-award-edit',
                        ])
                      ">
                  <v-select
                    class="custom-selectCSS" 
                    :clearable="false"
                    v-model="selectedTalentEmp"
                    :options="employeeLists"
                    :selectable="(options) => isSelectableTalentEmployee(options)"
                    label="employee_name"
                    @option:selected="getTalentAwardEmployee($event)"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('Talent Award employee')"
                    :vuelidateErrors="{
                      errors: v$.selectedTalentEmp.$errors,
                      value: 'Talent Award employee',
                    }"
                  ></v-errors>
                </div>

                <div class="col-md-6" v-else-if="
                        hasPermissions([
                          'trainer-orientation-award-list'
                        ])
                      ">
                  <v-select
                    class="custom-selectCSS" 
                    disabled
                    :clearable="false"
                    v-model="selectedTalentEmp"
                    label="employee_name"
                  >
                  </v-select>
                </div>

              </div>

              <div class="row mb-3">
                <label for="remark" class="col-md-3 form-label">
                  Employee ID
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div class="col-md-6">
                  <input
                    type="text"
                    id="talent_emp_id"
                    class="form-control"
                    disabled
                    v-model="selectedTalentEmp.employee_id"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <label for="level" class="col-md-3 form-label">
                  Participation Award
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-6" v-if="
                        hasPermissions([
                          'trainer-orientation-award-create',
                          'trainer-orientation-award-edit',
                        ])
                      ">
                  <v-select
                    class="custom-selectCSS"
                    :clearable="false"
                    v-model="selectedParticipationEmp"
                    :options="employeeLists"
                    :selectable="(options) => isSelectableParticipationEmployee(options)"
                    label="employee_name"
                    @option:selected="getParticipationEmployee($event)"
                  >
                  </v-select>
                  <v-errors
                    :serverErrors="errorFor('Participation Employee')"
                    :vuelidateErrors="{
                      errors: v$.selectedParticipationEmp.$errors,
                      value: 'Participation Employee',
                    }"
                  ></v-errors>
                </div>

                <div class="col-md-6" v-else-if="
                        hasPermissions([
                          'trainer-orientation-award-list'
                        ])
                      ">
                  <v-select
                    class="custom-selectCSS"
                    disabled
                    :clearable="false"
                    v-model="selectedParticipationEmp"
                    label="employee_name"
                  >
                  </v-select>
                </div>
              </div>

              <div class="row mb-3">
                <label for="remark" class="col-md-3 form-label">
                  Employee ID
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div class="col-md-6">
                  <input
                    type="text"
                    id="participation_emp_id"
                    class="form-control"
                    disabled
                    v-model="selectedParticipationEmp.employee_id"
                  />
                  <!-- <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors> -->
                </div>
              </div>

              <!-- end row -->
              <div class="row" v-if="
                        hasPermissions([
                          'trainer-orientation-award-create',
                          'trainer-orientation-award-edit',
                        ])
                      ">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'orientation-award' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'orientation-award-update'
                          ? updateAward()
                          : createAward()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      orientationBatch: [],
      employeeLists: [],
      selectedBatch: null,
      selectedTalentEmp: "",
      selectedParticipationEmp: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      selectedBatch: { required },
      selectedParticipationEmp: { required },
      selectedTalentEmp: { required },
    };
  },
  methods: {
    isSelectableAward(options) {
      if(this.selectedBatch) {
       return  !(options.batch_id == this.selectedBatch.batch_id || options.disabled);
      }
      return !options.disabled;
      // if(!this.selectedBatch || !options) return true;
      // return (!options.disabled || options.batch_id !== this.selectedBatch.batch_id);
    },
    isSelectableTalentEmployee(options) {
      if(!this.selectedTalentEmp || !options) return true;
      return this.selectedTalentEmp.employee_id != options.employee_id;
    },
    isSelectableParticipationEmployee(options) {
      if(!this.selectedParticipationEmp || !options) return true;
      return this.selectedParticipationEmp.employee_id != options.employee_id;
    },
    async getBatchData() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/orientation-award-get-fill-data`)
        .then((response) => {
          response.data.data.forEach((item) => {
            this.orientationBatch.push({
              batch_id: item.id,
              batch_name: item.batch,
              disabled: item.disabled,
              behavior_assessments: item.behavior_assessments,
            });
          });
          // console.log(this.orientationBatch)
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    getSelectedBatch(val) {
      this.employeeLists = [];
      this.selectedTalentEmp = "";
      this.selectedParticipationEmp = "";
      if (val) {
        val.behavior_assessments.forEach((emp) => {
          this.employeeLists.push({
            employee_id: emp.employee_id,
            employee_name: emp.employee_name,
          });
        });
      }
      // console.log(this.employeeLists)
    },
    getTalentAwardEmployee(val) {
      this.selectedTalentEmp = val;
    },
    getParticipationEmployee(val) {
      this.selectedParticipationEmp = val;
    },
    async createAward() {
      this.isLoading = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      const toSendData = {
        ori_batch: this.selectedBatch.batch_name,
        talent_award_employee_name: this.selectedTalentEmp.employee_name,
        talent_award_employee_id: this.selectedTalentEmp.employee_id,
        participation_award_employee_name:
          this.selectedParticipationEmp.employee_name,
        participation_award_employee_id:
          this.selectedParticipationEmp.employee_id,
      };
      await axios
        .post(`${this.baseUrl}admin/v2/orientation-award`, toSendData, {
          headers: {
            "Content-type": "appication/json",
          },
        })
        .then(() => {
          this.toast.success("Successfully created orientation award");
          this.isLoading = false;
          this.$router.push({ name: "orientation-award" });
        });
    },
    async getOrientationAwardById() {
      // this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/orientation-award/${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data;
          this.selectedBatch = { batch_name: data.ori_batch };

          this.orientationBatch.forEach((item) => {
            if(item.batch_name == data.ori_batch) {
              this.getSelectedBatch(item)
            }
          });
          this.selectedTalentEmp = {
            employee_id: data.talent_award_employee_id,
            employee_name: data.talent_award_employee_name,
          };
          this.selectedParticipationEmp = {
            employee_id: data.participation_award_employee_id,
            employee_name: data.participation_award_employee_name,
          };

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Something went wrong");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async updateAward() {
      this.isLoading = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      const toSendData = {
        ori_batch: this.selectedBatch.batch_name,
        talent_award_employee_name: this.selectedTalentEmp.employee_name,
        talent_award_employee_id: this.selectedTalentEmp.employee_id,
        participation_award_employee_name:
          this.selectedParticipationEmp.employee_name,
        participation_award_employee_id:
          this.selectedParticipationEmp.employee_id,
      };

      await axios
        .put(
          `${this.baseUrl}admin/v2/orientation-award/${this.$route.params.id}`,
          toSendData,
          {
            headers: {
              "Content-type": "appication/json",
            },
          }
        )
        .then(() => {
          this.toast.success("Successfully updated orientation award");
          this.isLoading = false;
          this.$router.push({ name: "orientation-award" });
        })
        .catch(() => {
          this.toast.error("Something went wrong");
          this.isLoading = false;
        });
    },
  },
  async created() {
    await this.getBatchData();
    if (this.currentRouteName == "orientation-award-update") {
      await this.getOrientationAwardById();
    }
   
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
